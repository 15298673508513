<template>
  <div>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre</label>
            <vs-input
              v-model="typeform.name"
              type="text"
              class="border-0 shadow-lg"
              border
              name="name"
              :class="{
                'is-invalid': typesubmit && $v.typeform.name.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.name.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Apellido</label>
            <vs-input
              v-model="typeform.lastName"
              type="text"
              class="border-0 shadow-lg"
              border
              name="lastName"
              :class="{
                'is-invalid': typesubmit && $v.typeform.lastName.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.lastName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.lastName.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Nombre de usuario</label>
            <vs-input
              v-model="typeform.userName"
              type="text"
              class="border-0 shadow-lg"
              border
              name="userName"
              :class="{
                'is-invalid': typesubmit && $v.typeform.userName.$error,
              }"
            />
            <div
              v-if="typesubmit && $v.typeform.userName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.typeform.userName.required">
                Este campo es requerido.
              </span>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Correo Electronico</label>
            <div>
              <vs-input
                v-model="typeform.email"
                type="email"
                name="email"
                class="border-0 shadow-lg"
                border
                :class="{
                  'is-invalid': typesubmit && $v.typeform.email.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.email.required">
                  Este campo es requerido.
                </span>
                <span v-if="!$v.typeform.email.email">
                  Ingrese un correo valido.
                </span>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6" v-if="!typeform._id">
          <div class="mb-3">
            <label>Contraseña</label>
            <div>
              <vs-input
                v-model="typeform.password"
                type="password"
                name="password"
                class="border-0 shadow-lg"
                border
              />
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>Rol</label>
            <div>
              <select
                v-model="typeform.role"
                name="role"
                class="form-select form-select-sm m-2 shadow-lg border-0"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in roles"
                  :key="index"
                  :value="item._id"
                  :selected="String(typeform.role) === String(item._id)"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>CLIENT</label>
            <div>
              <select
                v-model="typeform.client"
                name="client"
                class="form-select form-select-sm m-2 shadow-lg border-0"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in clients"
                  :key="index"
                  :value="item._id"
                  :selected="String(typeform.client) === String(item._id)"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>OPERATOR</label>
            <div>
              <select
                v-model="typeform.operator"
                name="operator"
                class="form-select form-select-sm m-2 shadow-lg border-0"
                placeholder="Seleccione.."
              >
                <option disabled selected>{{ $t('helpers.select') }}...</option>
                <option
                  v-for="(item, index) in operators"
                  :key="index"
                  :value="item._id"
                  :selected="String(typeform.operator) === String(item._id)"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="$emit('closeModa')" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    typeform: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clients: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    operators: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      roles: [],
    };
  },
  validations: {
    typeform: {
      name: {
        required,
      },
      lastName: {
        required,
      },
      userName: {
        required,
      },
      email: {
        required,
        email,
      },
      // role: {
      //   required,
      // },
    },
  },
  methods: {
    async save() {
      this.typesubmit = true;

      const role = this.roles.find((rol) => rol._id === this.typeform.role);

      const doc = { ...this.typeform, rol: role.name, role: role._id };

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log('error');

      if (doc._id) {
        await this.update(doc);

        if (!this.success)
          return Swal.fire('Error actualizando usuario', '', 'info');

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Usuario guardado con exito',
          showConfirmButton: false,
          timer: 1500,
        });

        this.$emit('closeModa');
      } else {
        await this.create(doc);

        if (!this.success)
          return Swal.fire('Error creando usuario', '', 'info');

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Usuario registrado con exito',
          showConfirmButton: false,
          timer: 1500,
        });

        this.$emit('closeModa');
      }
    },
    getRoles() {
      this.$http
        .get('roles')
        .then(({ data }) => {
          this.roles = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ...mapActions({
      create: 'user/create',
      update: 'user/update',
    }),
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user')).user;
    },
    ...mapGetters({
      success: 'user/getSuccess',
    }),
  },
  mounted() {
    this.getRoles();
  },
};
</script>

<style></style>
